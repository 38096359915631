<template>
  <div class="full-container bind-parts-container">
    <title-header :title="'锁体配件绑定'" />
    <div class="setting-container">
      <div class="flex nowrap setting-item">
        <p class="setting-tips">锁体ID：</p>
        <div class="flex-no-wrap fields-item">
          <input
              type="number"
              class="fields-input"
              v-model="sn_id"
          />
          <md-icon
              name="scan"
              size="lg"
              @click="controlScanModal('isSnScan', true, 'er')"
          ></md-icon>
        </div>
        <scan
            v-if="isSnScan"
            :code-type="'er'"
            @closeScan="controlScanModal('isSnScan', false, 'er')"
            @getCode="getScanLockCode"
        />
      </div>
      <div class="flex nowrap setting-item">
        <p class="setting-tips">控制盒ID：</p>
        <div class="flex-no-wrap fields-item">
          <input
              type="number"
              class="fields-input"
              v-model="box_id"
          />
          <md-icon
              name="scan"
              size="lg"
              @click="controlScanModal('isBoxScan', true, 'er')"
          ></md-icon>
        </div>
        <scan
            v-if="isBoxScan"
            :code-type="'er'"
            @closeScan="controlScanModal('isBoxScan', false, 'er')"
            @getCode="getScanLockCode"
        />
      </div>
      <div class="flex nowrap setting-item">
        <p class="setting-tips">电机ID：</p>
        <div class="flex-no-wrap fields-item">
          <input
              type="number"
              class="fields-input"
              v-model="motor_id"
          />
          <md-icon
              name="scan"
              size="lg"
              @click="controlScanModal('isMotorScan', true, 'er')"
          ></md-icon>
        </div>
        <scan
            v-if="isMotorScan"
            :code-type="'er'"
            @closeScan="controlScanModal('isMotorScan', false, 'er')"
            @getCode="getScanLockCode"
        />
      </div>
      <div class="flex nowrap setting-item">
        <p class="setting-tips">电池ID：</p>
        <div class="flex-no-wrap fields-item">
          <input
              type="number"
              class="fields-input"
              v-model="battery_id"
          />
          <md-icon
              name="scan"
              size="lg"
              @click="controlScanModal('isBatteryScan',true, 'er')"
          ></md-icon>
        </div>
        <scan
            v-if="isBatteryScan"
            :code-type="'er'"
            @closeScan="controlScanModal('isBatteryScan',false, 'er')"
            @getCode="getScanLockCode"
        />
      </div>
      <!-- 添加设置 -->
      <md-button
          type="primary"
          size="small"
          class="add-btn"
          round
          @click="bindParts"
          style="margin-top: 2rem"
      >确认绑定</md-button
      >
    </div>

  </div>
</template>

<script>
import Scan from "../../components/Scan.vue";
import axios from "axios";
import apiConfig from "../../assets/config/api.config";
import {Toast} from "mand-mobile";
import TitleHeader from "../../components/TitleHeader.vue";
const formDataHeader = {'Content-Type': 'application/x-www-form-urlencoded'};
export default {
  name: "bindParts",
  components: {
    TitleHeader,
    Scan: Scan
  },
  data() {
    return {
      currentScan: '',
      sn_id: '',
      box_id: '',
      motor_id: '',
      battery_id: '',
      isSnScan: false,
      isBoxScan: false,
      isMotorScan: false,
      isBatteryScan: false
    }
  },
  mounted() {
  },
  methods: {
    // 控制scan显示
    controlScanModal(str, bool, type = 'er') {
      if(bool) {
        this.currentScan = str;
      }else {
        this.currentScan = '';
      }
      if (type === "er") {
        this[str] = bool;
      }
    },
    getScanLockCode(code) {
      this[this.currentScan] = false;
      let item = this.currentScan.toLowerCase();
      item = item.slice(2, item.length-4);
      this[item + '_id'] = code;
    },
    // 确定绑定
    bindParts() {
      // 验证是否全填写
      if(this.sn_id && this.box_id && this.motor_id && this.battery_id) {
        let params = new FormData();
        params.append('sn_id', this.sn_id)
        params.append('box_id', this.box_id)
        params.append('motor_id', this.motor_id)
        params.append('battery_id', this.battery_id)
        axios.post(apiConfig.bindParts, params, {headers: formDataHeader}).then(({data}) => {
          if(data.code === 0) {
            Toast.info('绑定成功')
          }else {
            Toast.info(data.msg)
          }
        })
      }else {
        Toast.info('请填写完整绑定的ID信息')
      }

    }
  }
}
</script>

<style lang="less" src="./../Setting/setting.less"></style>

